<template>
  <CCardBody>
    <!-- Filters -->
    <CCard class="filters" accent-color="warning">
      <CCardHeader role="button" class="text-warning shadow-none card-header" @click="resetFilters">
        <strong class="m-0">
          <CIcon :name="`cil-filter${$store.state.filterShow ? '-x' : ''}`" /> Filters
        </strong>
        <div class="card-header-actions">
          <CIcon :name="`cil-chevron-${$store.state.filterShow ? 'bottom' : 'top'}`" />
        </div>
      </CCardHeader>

      <CCollapse :show="$store.state.filterShow">
        <CCardBody class="p-2">
          <div class="form-container">
            <!-- Restaurant Filter -->
            <div class="restaurant-filter">
              <v-select
                class="v-select-filter"
                placeholder="Select restaurant.."
                v-model="selectedRestaurant"
                :options="restaurants"
                @input="inputFilter"
              />
            </div>

            <!-- User Filter -->
            <div class="user-filter">
              <v-select
                class="v-select-filter"
                :placeholder="users.length == 0 ? 'Search for a user..' : 'Select or search for a user..'"
                v-model="selectedUser"
                :options="users"
                :searchable="true"
                :loading="searching && users.length == 0"
                @search="searchUsers"
                @input="inputFilter"
              >
                <template v-slot:option="user">
                  <div class="d-flex align-items-center">
                    <div class="c-avatar mr-2">
                      <img :src="user.image_icon || user.gravatar" class="c-avatar-img">
                    </div>
                    <span class="small"><strong>{{ user.first_name }}</strong> #{{ user.id }}  ({{ user.email }})</span>
                  </div>
                </template>
                <template slot="selected-option" slot-scope="user">
                  {{ user.first_name }} #{{ user.id }}
                </template>
                <span slot="no-options">Type to search users..</span>
              </v-select>
            </div>

            <!-- Search -->
            <div class="search-filter">
              <CInput class="mb-0 search" type="search" placeholder="Search for something else ..." v-model="search"
                @input="searchFilter" />
            </div>
          </div>
        </CCardBody>
      </CCollapse>
    </CCard>

    <div class="table-responsive">
      <CDataTable
        striped
        hover
        :items="loadedItems"
        :fields="fields"
        :sorter="{ external: true, resetable: true }"
        :column-filter="{ external: true, lazy: true }"
        :itemsPerPageSelect="{
          external: true,
          values: [5, 15, 25, 50, 100, 250, 500],
        }"
        :items-per-page.sync="itemsPerPage"
        :loading="loading"
        :noItemsView="{
          noResults: 'No filtering results are available!',
          noItems: 'No items found!',
        }"
        @update:sorter-value="sorterValue"
        @pagination-change="paginationChange"
      >
        <!-- Actions -->
        <template #actions="{ item, index }">
          <td>
            <CButton :to="{ name: 'Edit Voucher', params: { id: item.id } }" v-c-tooltip="{
              content: 'Edit',
              placement: 'top',
            }" size="sm" color="info" variant="ghost" shape="pill">
              <CIcon name="cil-align-left" />
            </CButton>
          </td>
        </template>

        <!-- id -->
        <template #id="{ item }">
          <td>
            {{ item.id }}
          </td>
        </template>

        <!-- Code -->
        <template #code="{ item }">
          <td>
            <strong>{{ item.code }}</strong>
          </td>
        </template>

        <!-- Name -->
        <template #name="{ item }">
          <td>{{ item.name }}</td>
        </template>

        <!-- Discount amount -->
        <template #discount_amount="{ item }">
          <td class="text-center">
            <strong v-if="item.is_fixed">{{ item.discount_amount | toCurrency('remove_thousand_separator') }}</strong>
            <strong v-else>% {{ item.discount_amount }}</strong>
          </td>
        </template>

        <!-- Min. Value -->
        <template #minvalue="{ item }">
          <td class="text-center">
            <strong>{{ item.minvalue | toCurrency('remove_thousand_separator') }}</strong>
          </td>
        </template>

        <!-- Uses / Max -->
        <template #uses="{ item }">
          <td class="text-center">
            <strong>{{ item.uses || 0 }}</strong> / {{ item.max_uses || 0 }}
          </td>
        </template>

        <!-- Max. Usage Per User -->
        <template #max_uses_user="{ item }">
          <td class="text-center">
            <strong>{{ item.max_uses_user }}</strong>
          </td>
        </template>

        <!-- Is Fixed -->
        <template #is_fixed="{ item }">
          <td class="text-center">
            <strong class="text-success">{{ item.is_fixed ? '✔' : '' }}</strong>
          </td>
        </template>

        <!-- User -->
        <template #user_id="{ item }">
          <td>
            <CLink v-if="item.user" :to="{ name: 'User', params: { id: item.user_id } }"
              v-c-tooltip="{ content: `# ${item.user_id}` }">
              {{ item.user.first_name }}
            </CLink>
          </td>
        </template>

        <!-- Valid Until The User -->
        <template #untill_user_id="{ item }">
          <td class="text-center">
            <strong>{{ item.untill_user_id }}</strong>
          </td>
        </template>

        <!-- Restaurant -->
        <template #resto_id="{ item }">
          <td>
            <CLink v-if="item.restaurant" :to="{ name: 'View Restaurant', params: { id: item.resto_id } }"
              v-c-tooltip="{ content: `# ${item.resto_id}` }">
              {{ item.restaurant.restaurant_name }}
            </CLink>
          </td>
        </template>

        <!-- Starts at -->
        <template #starts_at="{ item }">
          <td>
            <span v-if="item.starts_at">{{ moment(item.starts_at).format('LL') }}</span>
          </td>
        </template>

        <!-- Expires at -->
        <template #expires_at="{ item }">
          <td>
            <span v-if="item.expires_at">{{ moment(item.expires_at).format('LL') }}</span>
          </td>
        </template>
      </CDataTable>
    </div>

    <!-- Actions -->
    <CCard class="actions sticky-bottom">
      <CCardBody class="p-2">
        <div class="d-flex flex-wrap align-items-center" style="gap: 0.75rem">
          <div>
            <h5 class="mt-1">Total: <span class="d-inline count bg-primary pb-1">{{ total }}</span></h5>
          </div>

          <div v-if="pages > 1" class="ml-auto">
            <CPagination align="center" :dots='false' :pages="pages" :active-page.sync="activePage" />
          </div>
        </div>
      </CCardBody>
    </CCard>

    <mc-spinner :opacity="0.8" v-show="loading" :mtop="(32 + mcSpinnerMarginTop) + 'px'" />
  </CCardBody>
</template>

<script>
import { mapState } from 'vuex'
import _debounce from 'lodash/debounce';

export default {
  name: "BackendTable",
  data() {
    return {
      loadedItems: [],
      fields: [
        { key: "actions", label: "", filter: false, sorter: false, _style: "min-width: 48px; width: 48px;" },
        { key: "id", label: "ID", filter: false, _style: "min-width: 64px; width: 64px;" },
        { key: "code", label: "Code", filter: false, sorter: false, _style: "min-width: 96px; width: 200px;" },
        { key: "name", label: "Name", filter: false, _style: "min-width: 150px;" },
        { key: "discount_amount", filter: false, _style: "min-width: 96px; width: 120px; text-align: center;" },
        { key: "minvalue", label: "Min. Value", filter: false, _style: "min-width: 96px; width: 120px; text-align: center;" },
        { key: "uses", label: "Uses / Max", filter: false, _style: "min-width: 100px; width: 120px; text-align: center;" },
        { key: "max_uses_user", label: "Max. Usage Per User", filter: false, _style: "min-width: 110px; width: 120px; text-align: center;" },
        // { key: "is_fixed", filter: false, _style: "min-width: 64px; width: 84px; text-align: center;" },

        { key: "user_id", label: "User", filter: false, _style: "min-width: 150px;" },
        { key: "untill_user_id", label: "Valid Until The User", filter: false, _style: "min-width: 100px; width: 120px; text-align: center;" },
        { key: "resto_id", label: "Restaurant", filter: false, _style: "min-width: 150px;" },
        { key: "starts_at", label: "Start", filter: false, _style: "min-width: 140px; width: 140px;" },
        { key: "expires_at", label: "Expire", filter: false, _style: "min-width: 140px; width: 140px;" },
      ],

      activePage: 1,
      pages: 1,
      total: 0,
      items_per_page: null,
      orderBy: null,
      direction: null,

      loading: false,
      searching: false,

      // Element Cover Spinner
      mcSpinnerMarginTop: 0,

      // Filter işlemleri için
      search: null,
      restaurants: [],
      users: [],
    };
  },

  beforeMount() {
    this.searchFilter = _debounce(async () => {
      await this.getAllItems();
    }, 1000);

    this.searchUsers = _debounce(async (val) => {
      await this.getUsers(val);
    }, 500);
  },

  async mounted() {
    this.activePage = this.queryPage;

    await Promise.all([
      this.getRestaurants(),
      this.getAllItems()
    ]);

    window.onscroll = () => {
      this.mcSpinnerMarginTop = document.documentElement.scrollTop;
    }
  },

  watch: {
    reloadParams() {
      if (this.queryPage != this.activePage)
        this.$router.push({ name: 'Vouchers', query: { page: this.activePage } });

      this.onTableChange();
    },
  },

  computed: {
    ...mapState(['filters']),

    itemsPerPage: {
      get: function () { return this.items_per_page ? this.items_per_page : parseInt(process.env.VUE_APP_ITEMS_PER_PAGE) },
      set: function (newValue) { this.items_per_page = newValue }
    },
    reloadParams() { return [this.activePage]; },
    queryPage() { return parseInt(this.$route.query.page) || 1; },

    selectedRestaurant: {
      get() { return this.filters.vouchers.selectedRestaurant },
      set(value) { this.filters.vouchers.selectedRestaurant = value },
    },
    selectedUser: {
      get() { return this.filters.vouchers.selectedUser },
      set(value) { this.filters.vouchers.selectedUser = value },
    },
  },

  methods: {
    async getAllItems() {
      this.loading = true;

      var url = new URL(this.$backend.VOUCHERS.GET_ALL),
        params = {
          page: this.activePage,
          itemsPerPage: this.itemsPerPage,
          'order-by': this.orderBy,
          direction: this.direction,

          'only-availables': false,
          'restaurant-id': this.selectedRestaurant?.value,
          'user-id': this.selectedUser?.id,
          search: this.search,
        };

      Object.keys(params).forEach((key) => {
        if (
          typeof params[key] !== "undefined" &&
          params[key] !== null &&
          params[key] !== "" &&
          params[key].length !== 0
        )
          url.searchParams.append(key, params[key]);
      });

      this.$axios.get(url)
        .then((response) => {
          return response.data;
        })
        .then((items) => {
          // Son sayfadan daha büyük bir queryPage çağrımı (?page=XXX) yapılırsa;
          if (items.current_page > items.last_page)
            this.activePage = 1;
          else {
            this.activePage = items.meta.current_page;
            this.pages = items.meta.last_page;
            this.total = items.meta.total;

            this.loadedItems = items.data;
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
    async getRestaurants() {
      const storedRestaurants = sessionStorage.getItem('restaurants');

      if (storedRestaurants) {
        this.restaurants = JSON.parse(storedRestaurants);
      } else {
        try {
          const response = await this.$axios.get(this.$backend.RESTAURANT.GET_ALL_NAME);
          this.restaurants = response.data;
          sessionStorage.setItem('restaurants', JSON.stringify(this.restaurants));
        } catch (error) {
          this.restaurants = [];
        }
      }
    },
    async getUsers(val) {
      if (val.length < 3) {
        return;
      }

      this.searching = true;
      try {
        const response = await this.$axios.get(this.$backend.USERS.GET_ALL, {
          params: {
            search: val,
            'get-relations': false,
            'except-couriers': false,
          }
        });
        this.users = response.data.data.map((el) => {
          return { ...el, label: `i#${el.id} ${el.first_name} ${el.email}` };
        });
      } catch (error) {
        this.users = [];
      } finally {
        this.searching = false;
      }
    },

    onTableChange() { // Active Page güncellendiğinde çağrılıyor.
      this.getAllItems();
    },
    paginationChange(value) {
      this.itemsPerPage = value;
      this.getAllItems();
    },
    sorterValue(item) {
      this.orderBy = item.column;
      this.direction = item.column ? (item.asc ? "asc" : "desc") : null;
      this.getAllItems();
    },

    // Filters
    async inputFilter() {
      await this.getAllItems();
    },
    resetFilters() {
      if (this.$store.state.filterShow) {
        this.selectedRestaurant = this.selectedUser = null;
        this.search = "";
        this.getAllItems();
      }
      this.$store.commit('toggle', 'filterShow');
    },
  },
};
</script>

<style>
.table th,
.table td {
  padding: 0.5rem 0.25rem;
  vertical-align: middle;
}

.search input {
  font-size: 1rem !important;
}
</style>

<style scoped>
.filters .form-container .restaurant-filter,
.filters .form-container .user-filter {
  width: calc(50% - 1rem);
}
</style>
